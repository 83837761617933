import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/index.css";

export default function Home() {
  return (
    <div className="container NotFound">
      <br />
      <h1>Not found :(</h1>
    </div>
  );
}
